import React from 'react'
import img from '../../images/endermomasaz-zmiana.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const EndermomasazCialo = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Endermomasaż | Modelowanie Sylwetki'
                    description='Endermomasaż to zabieg numer jeden na świecie w zwalczaniu cellulitu i
          nadmiaru tkanki tłuszczowej. Endermomasaż nie tylko redukuje cellulit, ale również
          poprawia metabolizm, zmniejsza blizny i rozstępy, ujędrnia i zwiększa
          napięcie skóry.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/endermomasaz'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Endermomasaż</h1>
                            <div className='section'>
                                <div className='image-container long'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='Endermomasaż zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Endermomasaż to zabieg numer jeden na świecie w
                                        zwalczaniu cellulitu i nadmiaru tkanki
                                        tłuszczowej. Nazywany „Królową lub Królem wśród
                                        zabiegów”. Endermomasaż nie tylko redukuje
                                        cellulit, ale również poprawia metabolizm,
                                        zmniejsza blizny i rozstępy, ujędrnia i zwiększa
                                        napięcie skóry. Zabieg jest bardzo relaksujący i
                                        niewiarygodnie skuteczny. Jest to masaż
                                        podciśnieniowy całego ciała specjalną głowicą,
                                        podczas którego następuje redukcja tkanki
                                        tłuszczowej. Jest to bardzo bezpieczny zabieg dla
                                        osób, które mają przeciwwskazania do zabiegów z
                                        użyciem wyższej temperatury.
                                    </p>
                                    <p>
                                        Podczas zabiegu masowany fałd skóry jest zassany
                                        przez podciśnienie wytwarzane w komorze, która
                                        posiada możliwość ssania ciągłego lub
                                        pulsacyjnego. Masaż podciśnieniowy stymuluje
                                        krążenie, wykonuje intensywny drenaż limfatyczny
                                        całego ciała. Dodatkowo podczas zabiegu
                                        wykorzystywana jest fala RF i laser, które
                                        powodują wytworzenie ciepła, rozgrzewają naskórek,
                                        skórę właściwą oraz tkankę podskórną. Zabiegi są
                                        całkowicie nieinwazyjne, wspomagają jedynie
                                        naturalne procesy zachodzące w organizmie. U wielu
                                        osób po serii zbiegów efekty są porównywalne do
                                        efektów chirurgicznej liposukcji i to właśnie
                                        dlatego zabieg ten, uważa się na świecie za zabieg
                                        numer jeden w zwalczaniu cellulitu i nadmiaru
                                        tkanki tłuszczowej. Pojedynczy zabieg trwa 45
                                        minut.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>ENDERMOMASAŻ</h2>
                                        <ul>
                                            <li>
                                                <p>
                                                    ENDERMOMASAŻ CAŁE CIAŁO - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>
                                                    45 MIN | 247 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    ENDERMOMASAŻ CAŁE CIAŁO -{' '}
                                                    <span>PAKIET 8 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    45 MIN | 1414 zł
                                                </span>
                                            </li>
                                            <li>
                                                <p>
                                                    ENDERMOMASAŻ CAŁE CIAŁO -{' '}
                                                    <span>PAKIET 12 </span>ZABIEGÓW
                                                </p>
                                                <span className='prices'>
                                                    45 MIN | 1887 zł
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default EndermomasazCialo
